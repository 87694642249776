@import "../../assets/variables";

.navbar {
    
    background-color: $OrangeCol2;
    // background-color: red;
    width:100%;
    height:64px;

    
    z-index:1;
    position:fixed;
    left:0;
    top:0;  


    @media screen and (max-width: 700px) {
        top:unset;
        // bottom:275px;
        // width:50px;
        bottom:0;
    }

    img {
        image-rendering: auto;
        width:32px;
    }

    .centerDiv {
        position:absolute;
        top:0;
        left: 50%;
        transform: translateX(-50%);
        max-width:1270px;
        // min-width:1270px;
        text-transform: lowercase;

        // margin: 0 auto;
        margin-bottom: $gap1;
        display: flex;
        justify-content: center;
        align-content:center;

        text-align: center;
        z-index:1;

        // @media screen and (max-width: 700px) {
        //     flex-direction: column;
        //     flex-wrap: wrap;
        // }

        .name {
            position: absolute;
            left: $gap1;
            padding-left:0;
            z-index:10; 
        }

        .navButton {
            margin:2px;

            img {
                image-rendering: unset;
                image-rendering: unset;
            }
        }

        button {
            border: 0;
            text-transform: lowercase;
        }
        a, button {

            background-color: $OrangeCol2;
            font-size: 16px;
            font-weight:400;
            color: $whiteCol;
            padding: 8px 16px;
            position:relative;
            // padding-right:0px;
            // padding-left:32px;
            z-index:1;
            text-decoration: none;
            display: inline-block;
            vertical-align: baseline;
            display:flex;
            align-items: center;
            height:60px;
            // @media screen and (max-width: 700px) {
            //     height:55px;
            // }

            &::after {
                position:absolute;
                content:'';
                left: 50%;
                bottom: 15px;
                width:0%;
                height:0%;
                background-color:$whiteCol;
                z-index:-1;
                // transition: all ease 0.1s;
            }

            body.hasHover &:hover {
                // color:black;

                &::after {
                    height:2%;
                    left: 10%;
                    width: 80%;
                    // transition: all ease 0.05s;
                }
            
            }
        }
    }
}


.gameBanner {
    margin:8px;
    img {
        border: 4px black solid;
        width:100%;
        max-width:620px;
        flex:1;
    }
}

.aboutContainer {
    // max-width:620px;
    margin: 0 auto;
    display:flex;
    flex-direction: column;
    align-content: center;  

    p {
        font: 400 44px 'Solway', serif;
        font-size: 18px;
        line-height: 1.3545454545em; 
        color:black;
    }
    span {
        // font: 800 44px 'Spartan', sans-serif;
        font: 600 44px 'Solway', serif;
        font-size: 18px;
        line-height: 1.3545454545em; 
        color:black;
        // font-style:italic;
    }
    a {
        font: 400 44px 'Solway', sans-serif;
        font-size: 18px;
        line-height: 1.4545454545em; 
    }

    h1 {
        // margin-left:-32px;
        font: 500 45px 'Spartan', sans-serif !important;
        color:black;
        margin-bottom: 8px;
        font-weight:700 !important;
        
    }
    h2 {
        font: 500 29px 'Spartan', sans-serif;
        // font: 500 29px 'Solway', serif;
        color:black;
        margin-bottom: 8px;
        font-weight:700;
        // font-style:italic;
    }
    hr {
        border-color: black;
        border-top: 1px black solid;
        width:100%;
        margin: 0;
        margin-top:16px;
    }

    .art-image {
        image-rendering: auto;
        border: 2px black solid;
        // flex:1;
        // width:1080px;
        width:25%;
        height:100%;
        // margin:8px;
        // height:1350px;
    }

    .picContainer {
        display:flex;
        width:100%;
 
        flex-direction: row;
        flex-wrap:wrap;
        justify-content: space-between;
    }

    // video {
    //     width:30%;
    // }
    .aboutContainerContent {
        display: flex;
    }

    .aboutContainerLeft {
        // width:75%;
    }

    .aboutContainerRight {
        // width: 25%;
        video {
            width:100%;
            height:100%;
        }
    }

    #starkidId {
        width:100%;
        max-width:646px;
    }
}

.gameContainer {
    // max-width:620px;
    // width:50%;
    margin: 0 auto;
    display:flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    align-content: center;  
    justify-content: space-around;  
}

.gameContainer2 {
    // max-width:620px;
    // width:50%;
    margin: 0 auto;
    display:flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    align-content: center;  
    justify-content: space-between;  
    
    .gameBanner {
        margin:0px !important;
        width:34%;
        
        img {
            border: 2px black solid;
            // border:0px !important;
        }
        
    }
}

.videoContainer {
    display:flex;
    flex-direction: column;
    video {
        
    }
    .videoContainer-left{

    }
}


    
.soundcloudPlayer {
    font-size: 10px;
    color: #cccccc;
    line-break: anywhere;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
    font-weight: 100;
}


.modal-header {
    border-radius: 0;
    border:0;
    border: 1px $whiteCol solid;

    background: $OrangeCol2;
    color: $whiteCol;
    .modal-title {
        color: white;
    }
    .close {
        color: white;
        opacity:0.75 !important;
        text-shadow: 0 1px 0 black;

        body.hasHover &:hover{
            opacity:1 !important;
        }
    }
}
.modal-body {
    background: rgba(255, 255, 255, 0.726);
    // background-size: 2400px;
    // background-position: 50% 50%;
    // p {
    //     color: $whiteCol;
    //     margin-bottom:32px;
    // }
    // h1 {
    //     color: $whiteCol;
    // }
}