$OrangeCol2: #03030a;
$cardBg: #0d0e1a;
$OrangeCol: #ff0078;
// $whiteCol: #fcf8eb;
$whiteCol: #fdfff0;
// $whiteCol: #0f0f0f;
$yellowCol: #f8fce1;
$yellowCol2: hsl(0, 0%, 96%);
$yellowCol3: hsla(0, 0%, 96%, 0.068);

// $bgCol: #070b14;
$purple: #4d1a4c;
$purple2: #000000;
$bgCol: #0c010c;
// $purpleDark: #0f030f;

$gap1: 32px;
$gap2: 8px;