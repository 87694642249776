@import "../../assets/variables";

// $bgCol: rgb(12, 11, 11);
//  $OrangeCol: #E94B3CFF;

.main-banner {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    // background: $OrangeCol2  no-repeat;

    background: url('../../img/rect6.png') no-repeat; //THIS IS THE GOOD CIRCLE ONE
    // background: url('../../img/watercolor-10.png') no-repeat; //THIS IS THE GOOD CIRCLE ONE

    // @media screen and (max-width: 1000px) {
    //     background: url('../../img/circlespin3.png') no-repeat;
    // }

    background-size: 1920px;
    // background-size: cover;
    background-position: 50% 50%;
    // margin-top: $gap1;


    // margin: 32px 0;
    padding: 16px;
    position: relative;
    text-align: center;

    // height:550px;
    height:360px;

    max-width:1400px;

    margin: 42px auto;
    margin-top:48px;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
        
    @media screen and (max-width: 1000px) {
        height:100%;
        margin: 24px 8px;

    }

    @media screen and (max-width: 700px) {
        height:100%;
        margin: 0px 8px;
        // margin-top:32px;

    }

    img {
        width: 1792px;
        margin-left:50%;
        margin-top:50%;
        transform: translate(-71%, -83%);
        position: absolute;
    }
    .banner-header {
        align-items: center;
        display: flex;
        flex-direction: column;

        // margin-bottom: 32px;
        position: relative;
        z-index: 1;
        height:110px;
        h1 {
            // text-align: left;
            // font: 100 70px 'Solway', serif;
            font: 400 25px 'Spartan', sans-serif;
            font-weight:400;
            
            color: $whiteCol;
            text-align:left;
            transform: translateY(-20px);
        }
        
        .contact-link {
            text-transform: uppercase;
            font: 400 18px 'Spartan', sans-serif;
            letter-spacing: .08em;
            line-height: 1.5em;
            color: $whiteCol;
        }

        .logo-container {

            width: 150px;
            height: 150px;
    
            svg {
                pointer-events:none;
                width: 230px;
                
                position: absolute;
                left: 50%;
                margin-left: -115px;
                margin-top: -25px;

                fill:$whiteCol;
            }
        }
    }

    .main-banner-content {
        margin: 0 auto;
        width: 100%;
        
        max-width: 772px;
        position: relative;
        
        display: flex;
        justify-content: space-around;
        flex-direction: column;

        h1 {
            text-align: center;
         
            color: $whiteCol;
            // font: 600 50px 'Solway', serif;
            font: 900 78px 'Spartan', sans-serif;
            letter-spacing: 0.03em;
            line-height: 1.2727272727em;
            margin-bottom: 8px;

            padding-top:0px;
            .span1 {
                font-weight: 200;
            }
        }

        h2 {
            margin-top:-16px;
            color: $whiteCol;
            font: 600 35px 'Solway', sans-serif;
            margin-bottom:16px;

        }
        h3 {
            color: $whiteCol;
            font: 500 30px 'Solway', sans-serif;
            font-style: italic;
            margin-bottom:8px;
        }
        p {
            font: 400 22px 'Spartan', sans-serif;
            color: $whiteCol;
            margin: 6px auto 40px auto;
            width: 63.33333333%;
            font-style: italic;
            line-height: 1.4545454545em; 
            // text-align:left;
            
        }
        .email {
            margin-top:-8px;
            margin-bottom: 0px;
            font-style: normal;
        }

        .title-container {
            padding-top:32px;
            height:100%;
        }


        @media screen and (max-width: 700px) {
            // text-align:left;

            h1 {
                // text-align:left;
                font-size: calc(80vw/8);
                margin:0;
                margin-bottom:8px;
            }
            hr {
                // padding: 0;
                // margin:0;
                // visibility: hidden;
            }
            h2 {
                font-size: calc(53vw/10);
                margin-bottom:8px;
            }

            p {
                font-size: calc(50vw/15);
            }
            .title-container {
                padding-top:0;
                
            }
        }
    }

    .icon-container {
        display:flex;
        justify-content: center;
        margin: 0 auto 32px auto;
    }
    .social-icon {
        // position:absolute;
        // margin:16px;
        // margin-top:16px;
        width:52px;
        height:52px;
    }
    
    hr {
        color: $whiteCol;
        // border-top:0px;
        border-style: dashed;
        // margin-bottom:32px;
        opacity: 1;
        width:77%;
    }

    .social-container {
        display:flex;
        justify-content: space-around;
        // flex-direction: column;
        width:50%;
        margin: 0 auto;
        margin-top:8px;
        
        .small {
            max-width:120px;
        }
    }
}

.crosshair {

    // background-size: 161px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    image-rendering: pixelated;

    img {
        position: absolute;
        
        left: 50%;
        top: 50%;
        transform:  translate(-50%, -50%);
        width:1920px;
        height:1080px;
        opacity:0.75;
    }
}

#myVideo {
    position: fixed;
    right: 0;
    top: 0px;
    min-width: 100%;
    min-height: 100%;
    z-index:-1;
  }

