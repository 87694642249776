@import "assets/variables.scss";


@font-face {
  font-family: vcr;
  // src: url('./font/VCR_OSD_MONO.ttf') format('truetype');
  // src: url('./font/Pixellari.ttf') format('truetype');
  src: url('./font/perfect dos.ttf') format('truetype');
}
@font-face {
  font-family: yoster;
  src: url('./font/Pixellari.ttf') format('truetype');
}


* {
  box-sizing: border-box;
  font-family: 'Spartan', sans-serif;
}

html, body, div,  applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html, body {
  height:100%;
}

#root {
  // background: $bgCol url(./img/bigcanvasBackground.png);
  background-size: 2200px;
}

.App {
  max-width: 100%;
  overflow-x: hidden;
  text-align: left;
  font-family: 'Spartan', sans-serif;
  font-size: 1rem;
  overflow-y: hidden;
  height:100%;

}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $OrangeCol2;
    background-position: 0 0%;
    background-size: 1792px;
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    color: $whiteCol;
 
    // padding: 8px;
    @media screen and (max-width: 650px) {
      padding: 0;
    }
}

hr {
  border-color:rgba(255, 255, 255, 0.568);
  max-width:1270px;
}
a {
    font: 400 22px spartan, sans-serif;
    
    text-decoration: none;
    color: $OrangeCol;
}

h1 {
  font: 200 32px 'Solway', serif;
  line-height: 1.2941176471em;
  padding-top: 16px;
  margin-bottom: 24px;
}

p {
  font: 400 22px 'Spartan', sans-serif;
  font-size: 22px;
  line-height: 1.4545454545em; 
}

.button {
  text-transform: uppercase;
  font: 500 20px 'Spartan', sans-serif;
  letter-spacing: .08em;

  background: $OrangeCol;
  border: none;
  border-radius: 2px;
  color: $bgCol;

  outline: none;
  padding: 16px 24px 16px 24px;
  padding-bottom:10px;
  /* max-width: 210px; */
  width: 100%;
  cursor: pointer;
  line-height: 2.1em;
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}